<template>
  <div class="about">
    <h1>This is an about page</h1>
    <br><br>
    <input class="form-submit" type="submit" value="Logout" @click="SubmitLogout()">
  </div>
</template>

<script>
export default {
  name: 'About',
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
  },
  methods: {
    SubmitLogout() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }

  }

};
</script>